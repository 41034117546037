import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({palette, breakpoints, spacing}) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    width: '90%',
    backgroundColor: palette.background.default,
    boxShadow: '5px 5px 12px 0 rgba(51, 51, 51, 0.2)',
    borderRadius: spacing(0, 5),
    padding: '80px 100px',
    wordWrap: 'break-word',
    '& img': {
      [breakpoints.down('sm')]: {
        maxWidth: 'inherit',
      },
    },
    [breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
      padding: 0,
      boxShadow: 'none',
      borderRadius: 0,
      minWidth: '100%',
      '& div': {
        margin: 0,
      },
    },
  },
}))

export default useStyles
