import React from 'react'

import {contentSectionPropTypes} from 'helpers/propTypes'
import ContentSection from 'components/UI/ContentSection'
import Section from 'components/UI/Section'

import useStyles from './styles'

const ShadowBox = ({content, hasPaddingTop}) => {
  const classes = useStyles()

  return (
    <Section hasPaddingTop={hasPaddingTop} hasVerySmallPadding>
      <div className={classes.container}>
        <div className={classes.content}>
          <ContentSection
            showContent={content.showContentSection}
            title={content.title}
            variation={content.variation}
            description={content.description}
            image={content.image}
          />
        </div>
      </div>
    </Section>
  )
}

ShadowBox.propTypes = contentSectionPropTypes

ShadowBox.defaultProps = {
  hasPaddingTop: true,
}

export default ShadowBox
