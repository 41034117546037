import * as R from 'ramda'
import {graphql} from 'gatsby'
import {shape} from 'prop-types'
import loadable from '@loadable/component'
import React from 'react'

import {
  allContentfulCommittedKeyrusPropTypes,
  pageContextPropTypes,
} from 'helpers/propTypes'
import ContentSection from 'components/UI/ContentSection'
import Hero from 'components/UI/Hero'
import RoundButton from 'components/UI/RoundButton'
import Section from 'components/UI/Section'
import SEO from 'components/seo'
import ShadowBox from 'components/AboutUs/CommittedKeyrus/ShadowBox'
import Title from 'components/UI/Title'

const Awards = loadable(() =>
  import('components/AboutUs/CommittedKeyrus/Awards'),
)

const CommittedKeyrus = ({pageContext, data}) => {
  const countryData = R.path(['contentfulCountry'], data)
  const {
    imageOrVideo,
    metaDescription,
    metaTitle,
    pageTitle,
    shortDescription: {shortDescription},
    surtitle,
    contentSection1,
    contentSectionsTitle,
    contentSections,
    contentSection2,
    contentSection3,
    awards,
    awardsTitle,
    awardsCta,
    awardsSurtitle,
    kpiTitle,
    allInitiativesCta,
    allInitiativesCtaLink,
  } = R.pathOr(null, ['allContentfulCommittedKeyrus', 'nodes', 0], data)
  const awardsSlug = R.pathOr(
    null,
    ['allContentfulAwards', 'nodes', 0, 'slug'],
    data,
  )

  const pageMetaData = {
    metaTitle: R.pathOr('', ['metaTitle'], metaTitle) || metaTitle,
    metaDescription:
      R.pathOr('', ['metaDescription'], metaDescription) || metaDescription,
    siteMetaData: R.pathOr('', ['siteMetaData'], data),
    genericData: R.pathOr('', ['contentfulGenericData'], data),
  }

  return (
    <>
      <SEO
        pageContext={pageContext}
        countryData={countryData}
        pageMetaData={pageMetaData}
      />
      <Section hasPaddingTop={false} hasPaddingBottom={false}>
        <Hero
          hat={surtitle}
          title={pageTitle}
          description={shortDescription}
          descriptionType="description"
          media={imageOrVideo}
          titleType="title"
          isAlignCenter
        />
      </Section>
      {contentSection1 && (
        <ShadowBox content={contentSection1} hasPaddingTop={false} />
      )}
      <div style={{height: 65}}>
        <RoundButton href={allInitiativesCtaLink} arrow isCentered>
          {allInitiativesCta}
        </RoundButton>
      </div>
      {contentSections &&
        R.addIndex(R.map)(
          (content, index) => (
            <Section hasVerySmallPadding>
              {index === 0 && (
                <Title variant="h2">{contentSectionsTitle}</Title>
              )}
              <ContentSection
                showContent={content.showContentSection}
                title={content.title}
                // TO DO : reactivate the buttons when initiatives page is ready
                // callToAction={content.callToAction}
                variation={content.variation}
                description={content.description}
                image={content.image}
                link={content.link}
                typeOfLink={content.typeOfLink}
                isOpenInNewWindow={content.isOpenInNewWindow}
                fileToDownload={content.fileToDownload}
                pageContext={pageContext}
              />
            </Section>
          ),
          contentSections,
        )}
      <Section hasVerySmallPadding>
        <ContentSection
          showContent={contentSection2.showContentSection}
          callToAction={contentSection2.callToAction}
          title={contentSection2.title}
          variation={contentSection2.variation}
          description={contentSection2.description}
          image={contentSection2.image}
          isOpenInNewWindow={contentSection2.isOpenInNewWindow}
          link={contentSection2.link}
          typeOfLink={contentSection2.typeOfLink}
          pageContext={pageContext}
          countryData={countryData}
        />
      </Section>
      <ShadowBox content={contentSection3} />
      <Awards
        data={awards}
        title={awardsTitle}
        ctaTitle={awardsCta}
        hat={awardsSurtitle}
        countryData={countryData}
        awardsSlug={awardsSlug}
      />
      <Section hasVerySmallPadding>
        <Title variant="h2" type="title">
          {kpiTitle}
        </Title>
      </Section>
    </>
  )
}

CommittedKeyrus.propTypes = {
  data: shape({
    allContentfulCommittedKeyrus: allContentfulCommittedKeyrusPropTypes,
  }).isRequired,
  pageContext: pageContextPropTypes.isRequired,
}

export const pageQuery = graphql`
  query templateCommittedKeyrus($nodeLocale: String, $technicalName: String) {
    allContentfulCommittedKeyrus(filter: {node_locale: {eq: $nodeLocale}}) {
      nodes {
        imageOrVideo {
          file {
            contentType
            url
          }
        }
        pageTitle
        metaTitle
        metaDescription {
          metaDescription
        }
        shortDescription {
          shortDescription
        }
        surtitle
        contentSection1 {
          description {
            raw
          }
          image {
            file {
              contentType
              url
            }
          }
          title
          variation
        }
        allInitiativesCta
        allInitiativesCtaLink
        contentSectionsTitle
        contentSections {
          callToAction
          description {
            raw
          }
          image {
            gatsbyImageData(placeholder: BLURRED)
            file {
              contentType
              url
            }
          }
          title
          variation
          link
          typeOfLink
          isOpenInNewWindow
          fileToDownload {
            file {
              contentType
              url
            }
          }
        }
        contentSection2 {
          description {
            raw
          }
          image {
            file {
              url
              contentType
            }
          }
          title
          variation
          callToAction
          isOpenInNewWindow
          link
          typeOfLink
        }
        contentSection3 {
          description {
            raw
          }
          image {
            file {
              contentType
              url
            }
          }
          title
          variation
        }
        awards {
          name
          logo {
            file {
              contentType
              url
            }
          }
        }
        awardsTitle
        awardsCta
        awardsSurtitle
        kpiTitle
      }
    }
    allContentfulAwards {
      nodes {
        slug
      }
    }
    allContentfulService(filter: {node_locale: {eq: $nodeLocale}}) {
      edges {
        node {
          ...serviceFields
        }
      }
    }
    contentfulCountry(
      technicalName: {eq: $technicalName}
      node_locale: {eq: $nodeLocale}
    ) {
      ...countryDataFields
    }
    siteMetaData: site {
      ...SiteMetadata
    }
    contentfulGenericData {
      genericKeywords {
        content
      }
    }
  }
`

export default CommittedKeyrus
